import { Client, resources } from 'coinbase-commerce-node';
import { products } from '../data';

const api_key = process.env.NEXT_PUBLIC_COINBASE_API_KEY
var clientObj = Client.init(api_key);
clientObj.setRequestTimeout(3000);
const { Charge } = resources;

export const CreateDepositAddress = async (planId, depositAmout, userId, duration) => {
    const productPlan = products.find(product => product.id === planId)
    try {

        const chargeData = {
            name: productPlan.name,
            description: productPlan.description,
            pricing_type: "fixed_price",
            local_price: {
                amount: depositAmout,
                currency: productPlan.currency,
            },
            metadata: {
                id: productPlan.id,
                user_id: userId,
                deposit_amout: depositAmout,
                duration: duration,
                dailyPercentage: productPlan.dailyPercentage
            },
        };
        const charge = await Charge.create(chargeData);
        return { data: charge }
    } catch (e) {
        console.log(e)
        //return {  error: e  } 
    }

}

