 
import { Text, Button, Box } from '../primitives'
import {
  DropdownMenuItem,
  DropdownMenuContent,
} from '@/components/primitives/Dropdown'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export type InvestmentPlanOption = "hotPlan" | "silverPlan" | "bronzePlan" | "copperPlan" | "goldPlan"

const sortingOptions: InvestmentPlanOption[] = [
  'hotPlan',
  'silverPlan',
  'bronzePlan',
  'copperPlan',
  'goldPlan',
]

const nameForInvestmentOption = (
  option: InvestmentPlanOption,
  compact: boolean
) => {
  switch (option) {
    case 'hotPlan':
      return 'Hot Plan'
    case 'silverPlan':
      return 'Silver Plan'
    case 'bronzePlan':
      return 'Bronze Plan'
    case 'copperPlan':
      return 'Copper Plan'
    case 'goldPlan':
      return 'Gold Plan'
  }
}

type Props = {
  compact?: boolean
  option: InvestmentPlanOption
  onOptionSelected: (option: InvestmentPlanOption) => void
}

const PlanDropdown: FC<Props> = ({
  compact = false,
  option,
  onOptionSelected,
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          color="gray3"
          css={{
            px: '14px',
            width:'fit-content',
            justifyContent: 'space-between',
            '@md': {
              width: '220px',
              minWidth: 'max-content',
              px: '$4',
            },
          }}
        >
          <Text style="body1">{nameForInvestmentOption(option, compact)}</Text>
          <Box
            css={{
              color: '$gray10',
              transition: 'transform',
              '[data-state=open] &': { transform: 'rotate(180deg)' },
            }}
          >
            <FontAwesomeIcon icon={faChevronDown} width={16} />
          </Box>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenuContent css={{ width: '220px', mt: '$2', zIndex: 1000 }}>
        {sortingOptions.map((optionItem) => (
          <DropdownMenuItem
            key={optionItem}
            css={{ py: '$3' }}
            onClick={() =>
              onOptionSelected(optionItem as InvestmentPlanOption)
            }
          >
            {nameForInvestmentOption(optionItem, false)}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu.Root>
  )
}

export default PlanDropdown
