import React, { ComponentProps, FC, ReactNode, useState, useEffect } from 'react'
import { SWRResponse } from 'swr'
import { useWalletClient, useAccount, } from 'wagmi'
import { Button, Text, Input, Box, Flex } from '@/components/primitives'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { CSS } from '@stitches/react'
import { useMarketplaceChain } from '@/hooks'
import { Modal, ModalSize } from "../common/Modal"
import { styled } from '@/stitches.config'
import { useMediaQuery } from 'react-responsive'
import { v4 as uuid } from 'uuid';
import CopyText from '@/components/common/CopyText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import QRCode from "qrcode";
import CoinDropDown, { InvestmentCoinOption } from './CoinDropDown';
type RequestCompleted = {
  isRequestCompleted?: boolean
}

type Props = {
  onCloseComplete?: (data: RequestCompleted) => void,
  planId: any,
  planName: string,
  duration: number,
  min: number,
  max: number,
  buttonCss?: CSS
  buttonProps?: ComponentProps<typeof Button>
  buttonChildren?: ReactNode
  mutate?: SWRResponse['mutate'],
  onRequestComplete?: (data: any) => void
}
const Img = styled('img', {
  width: '100%',
  '@bp600': {
    height: 150,
    width: 150,
  },
  borderRadius: '$borderRadius',
})
const CoinPaymentsButton: FC<Props> = ({
  onCloseComplete,
  planId,
  planName,
  duration,
  min,
  max,
  mutate,
  onRequestComplete
}) => {
  const [showModal, setShowModal] = useState(false);
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  const [isRequestCompleted, setIsRequestCompleted] = useState(false)
  const [investmentCoin, setInvestmentCoin] =
    useState<InvestmentCoinOption>('btnBTC')
  const { address, isConnected } = useAccount()
  const [warningMessage, setWarningMessage] = useState<string>();
  const [depositAddress, setDepositAddress] = useState<string>();
  const [currencyName, setCurrencyName] = useState<string>();
  const { openConnectModal } = useConnectModal()
  const marketplaceChain = useMarketplaceChain()
  const compactToggleNames = useMediaQuery({ query: '(max-width: 800px)' })
  const canBuy = isConnected

  const isWalletConnected = () => {
    if (!canBuy) {
      openConnectModal?.()
    } else
      setShowModal(true)
  }
  const contentContainerCss: CSS = {
    borderBottomWidth: 1,
    marginBottom: 12
  }

  const ContentContainer = styled(Flex, {
    width: '100%',
    flexDirection: 'column',
    '@bp600': {
      flexDirection: 'row',
    },
    borderColor: '$panelBorder',
  })

  const MainContainer = styled(Flex, {
    flex: 1,

    minHeight: '500px',
    borderColor: '$panelBorder',
    borderTopWidth: 1,
    borderLeftWidth: 0,
    '@bp600': {
      borderTopWidth: 0,
      borderLeftWidth: 1,
    },

    defaultVariants: {
      direction: 'column',
    },
  })

  const createDepositRequest = async (
    endpoint: string,
    options: any) => {
    const response = await fetch(endpoint, options)
    const result = await response.json()
    setDepositAddress(result?.blockchainAddress?.deposirAddress)
    QRCode.toDataURL(result?.blockchainAddress?.deposirAddress).then(setqrCodeUrl);
  }

  const trigger = (
    <>
      <Button onClick={isWalletConnected} className="flex w-full items-center justify-center rounded-md bg-black p-3 text-base font-semibold transition duration-300 ease-in-out hover:bg-opacity-80">Coinpayments

      </Button>
    </>
  )

  return (
    <>
      <Modal size={ModalSize.MD} open={showModal} title={`Coinpayment Deposit`}
        onOpenChange={(open) => {
          if (
            !open &&
            onCloseComplete
          ) {
            const data: RequestCompleted = {
              isRequestCompleted: isRequestCompleted
            }
            onCloseComplete(data)
          }
          setShowModal(open)
        }} trigger={trigger}>
        <ContentContainer
          css={{
            ...contentContainerCss, borderLeftWidth: 'thin', borderLeftColor: '$panelBorder'
          }}
        >
          <MainContainer css={{ p: '$4' }}>
            <Flex css={{ gap: '$2', mb: '$4', flexDirection: 'row' }}> <label htmlFor="planName" className="gap-1">Selected Plan Name:</label> <b>{planName}</b></Flex>
            <Flex css={{ gap: '$2', mb: '$4', flexDirection: 'row' }}> <label htmlFor="duration" className="gap-1">Duration:</label><b>{duration} Days</b></Flex>
            <Flex css={{ gap: '$2', mb: '$4', flexDirection: 'column' }}></Flex>
            <Flex css={{ gap: '$2', mb: '$4', flexDirection: 'column' }}>
              <label htmlFor="depositCoin" className="gap-1">Please select your deposit currency *</label>
              <CoinDropDown
                compact={compactToggleNames}
                option={investmentCoin}
                onOptionSelected={async (option) => {
                  setInvestmentCoin(option)
                  setWarningMessage('')
                  let network = '';
                  let currency = '';
                  let coinType = '';
                  if (option == 'btnBTC') {
                    network = 'bitcoin';
                    currency = 'BTC';
                    coinType = 'BTC';

                  }
                  else if (option == 'btnLTC') {
                    network = 'litecoin';
                    currency = 'LTC';
                    coinType = 'LTC';
                  }
                  else if (option == 'btnETH') {
                    network = 'ethereum';
                    currency = 'ETH';
                    coinType = 'ETH';
                  }
                  else if (option == 'btnUSDT') {
                    network = 'tether';
                    coinType = 'USDT';
                    currency = 'USDT.SOL'
                    setWarningMessage('Please ensure to selected Solana(SOL) network at sending wallet with contract address!')
                  }
                  else if (option == 'btnUSDC') {
                    network = 'usdc';
                    coinType = 'USDC';
                    currency = 'USDC.SOL'
                    setWarningMessage('Please ensure to selected Solana(SOL) network at sending wallet with contract address!')
                  }
                  else if (option == 'btnDOGE') {
                    network = 'dogecoin';
                    coinType = 'DOGE';
                    currency = 'DOGE'
                  }
                  else if (option == 'btnMATIC') {
                    network = 'polygon';
                    coinType = 'MATIC';
                    currency = 'MATIC'
                  }
                  else if (option == 'btnSOL') {
                    network = 'solana';
                    coinType = 'SOL';
                    currency = 'SOL'
                  }
                  else if (option == 'btnBNB') {
                    network = 'binance';
                    coinType = 'BNB';
                    currency = 'BNB.BSC'
                    setWarningMessage('Please ensure to selected BNB Smart Chain(BEP20) network at sending wallet with contract address!')
                  }
                  else if (option == 'btnADA') {
                    network = 'cardano';
                    coinType = 'ADA';
                    currency = 'ADA.BEP20'
                    setWarningMessage('Please ensure to selected BNB Smart Chain(BEP20) network at sending wallet with contract addres!')
                  }
                  else if (option == 'btnBCH') {
                    network = 'bitcoincash';
                    coinType = 'BCH';
                    currency = 'BCH'
                  }
                  else if (option == 'btnTRX') {
                    network = 'troncoin';
                    coinType = 'TRX';
                    currency = 'TRX'
                  }
                  const data = {
                    _id: uuid(),
                    userId: address?.toLowerCase(),
                    network: network,
                    coinType: coinType,
                    destinationTag: '',
                    planId: planId,
                    isAddFund: false,
                    isMining: false,
                    isPortalAddrss: true,
                    currency: currency
                  }
                  setCurrencyName(data?.currency)
                  // Send the data to the server in JSON format.
                  const JSONdata = JSON.stringify(data)
                  // API endpoint where we send form data.
                  const endpoint = '/api/webBlockChainAddress'

                  // Form the request for sending data to the server.
                  const options = {
                    // The method is POST because we are sending data.
                    method: 'POST',
                    // Tell the server we're sending JSON.
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    // Body of the request is the JSON data we created above.
                    body: JSONdata,
                  }
                  await createDepositRequest(endpoint, options)
                }}>

              </CoinDropDown>
              {depositAddress != undefined ?
                <>
                  <Box className='justify-center text-center align-middle'>
                    <Box
                      css={{
                        mr: '$4',
                        marginBottom: '$4',
                        width: 120,
                        '@bp600': {
                          mr: 0,
                          width: '100%',
                        },
                      }}
                    >
                      <Text as='h3' style="subtitle1" ellipsify>Deposit {currencyName}</Text>
                      <div className="flex justify-center items-center">
                        <Img
                          src={qrcodeUrl}
                          alt="qrcode url"
                          className="block object-contain justify-center"
                          css={{
                            mb: '$2',
                            objectFit: 'cover'
                          }}
                        />
                      </div>

                    </Box>
                  </Box>

                  <Flex css={{ gap: '$2' }} className='flex flex-col'>
                    <Text css={{ fontSize: 'small' }}>Address</Text>

                    <Flex align="end" css={{ gap: 24 }}>
                      <CopyText
                        text={depositAddress?.toString()}
                        css={{ width: 'max-content', fontWeight: 'bold' }}
                      >
                        <Flex css={{ gap: '$2' }} className='flex'>
                          <Text style="body1" as="p" css={{ fontWeight: 'bold' }}>
                            {depositAddress}
                          </Text>
                          <Box css={{ color: '$gray10' }}>
                            <FontAwesomeIcon
                              icon={faCopy}
                              width={16}
                              height={16}
                            />
                          </Box>
                        </Flex>
                      </CopyText>
                    </Flex>
                  </Flex>
                </>
                : <></>}

              <Text css={{ fontSize: 'small' }}>{warningMessage}</Text>
              <Text css={{ fontSize: 'x-small' }}>Min amount {min} USDT Max amount {max}</Text>
              <Text className='text-xs mt-4 text-yellow-500' as='p' ><strong>Note:{' '}</strong> Maximize your investment potential by ensuring that your deposited amount includes the applicable wallet network fees. For example, if the network fee is 0.001 LTC, you should deposit a total of 1.001 LTC to ensure that your investment of 1 LTC reaches our platform after deducting the network fee.</Text>
            </Flex>
          </MainContainer>
        </ContentContainer>
      </Modal>
    </>
  )
}

export default CoinPaymentsButton
