import { Text, Flex, Box, Button, Input } from '../primitives'
import { faInfoCircle, faCoins, faChartArea, faChartColumn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import PlanDropdown, { InvestmentPlanOption } from './PlanDropdown';
import { useMediaQuery } from 'react-responsive'
import InvestmenButton from './InvestmenButton';
import { Slider } from "@material-tailwind/react";
import { formatNumber } from '@/utils/numbers';
export const InvestmentCalc = () => {
    const [investmentPlan, setInvestmentPlan] =
        useState<InvestmentPlanOption>('silverPlan')
    const compactToggleNames = useMediaQuery({ query: '(max-width: 800px)' })
    const selectedPlan = Array()
    const [internalValue, setInternalValue] = useState([10, 1000]);
    const [investmentValue, setInvestmentValue] = useState<any>();
    const [dailyReturn, setDailyReturn] = useState<any>(1.25);
    const [totalProfit, setTotalProfit] = useState<any>(1187.5);
    const [totalReturn, setTotalReturn] = useState<any>(2187.5);
    const [investmentDuration, setInvestmentDuration] = useState<any>(175);
    const [dailyProfit, setDailtProfit] = useState<any>(12.5);
    const [planName, setPlanName] = useState<any>('Silver Plan');
    const [planId, setPlanId] = useState<any>('b1754c14-d296-4b0f-a09a-030017f4461f');

    const calculateProfit = (changeValue: any) => {
        let dailtIntrest = 1.25
        let duration = 175
        if (investmentPlan == 'silverPlan') {
            dailtIntrest = 1.25
            duration = 175
        }
        else if (investmentPlan == 'bronzePlan') {
            dailtIntrest = 1.5
            duration = 150
        }
        else if (investmentPlan == 'copperPlan') {
            dailtIntrest = 1.75
            duration = 100
        }
        else if (investmentPlan == 'goldPlan') {
            dailtIntrest = 2.1
            duration = 90
        }
        else if (investmentPlan == 'hotPlan') {
            dailtIntrest = 5.1
            duration = 30
        }
        setInvestmentDuration(duration)
        let dailtReturn = changeValue * dailtIntrest / 100
        let totalReturn = dailtReturn * duration
        let totalProfit = totalReturn - changeValue
        setDailtProfit(formatNumber(dailtReturn, 2))
        setTotalProfit(formatNumber(totalProfit, 2))
        setTotalReturn(formatNumber(totalReturn, 2))
    }

    return (
        <Box
            css={{
                '@sm': {
                    px: '$0',
                },
            }}

        >
            <Flex
                css={{
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    zIndex: '10',
                    flexDirection: 'column',
                    gap: 36,
                    '@bp600': {
                        flexDirection: 'row',
                        gap: 0,
                    },
                }}
            >

                <Box className='w-full lg:w-7/12 xl:w-7/12' css={{ alignItems: 'flex-start', px: '$1', textAlign: 'left', gap: 18, '@bp600': { alignItems: 'flex-end', gap: 8 } }}>
                    <Box className="wow fadeInUp mx-auto w-full text-center flex-wrap mt-10" data-wow-delay=".2s">
                        <Text className="text-2xl lg:text-4xl xl:text-4xl font-medium leading-normal uppercase"  css={{color:'$secondary12'}}>Calculate Your investment</Text>
                        <h1></h1>
                        <Text css={{ color: '$gray11' }} className="text-2xl lg:text-4xl xl:text-2xl font-medium leading-normal">How Much Can You Save With Plans?</Text>
                        <Box className='border-b-4 border-indigo-500 mt-5'></Box>
                    </Box>
                    <Box css={{ width: '100%' }}>
                        <Box css={{
                            flexDirection: 'row',
                            gap: 24,
                            '@bp800': {
                                alignItems: 'center',
                                flexDirection: 'row',
                            },
                        }}
                        >
                            <Box className='mt-5'>
                                <PlanDropdown
                                    compact={compactToggleNames}
                                    option={investmentPlan}
                                    onOptionSelected={(option) => {
                                        selectedPlan.splice(0)
                                        let dailtIntrest = 1.25
                                        let duration = 175
                                        if (option == 'silverPlan') {
                                            setPlanName('Silver Plan')
                                            setPlanId('2b5dd18a-bb81-477f-b6c8-fecc17eebfb3')
                                            dailtIntrest = 1.25
                                            duration = 175
                                            selectedPlan.push({ min: '10', max: '1000' })
                                            setInternalValue([selectedPlan[0].min, selectedPlan[0].max])
                                            setDailyReturn(dailtIntrest)
                                        }
                                        else if (option == 'bronzePlan') {
                                            setPlanName('Bronze Plan')
                                            setPlanId('c1f8baf9-4248-4f65-8b4c-bca96d8d9de4')
                                            dailtIntrest = 1.5
                                            duration = 150
                                            selectedPlan.push({ min: '100', max: '5000' })
                                            setInternalValue([selectedPlan[0].min, selectedPlan[0].max])
                                            setDailyReturn(dailtIntrest)

                                        }
                                        else if (option == 'copperPlan') {
                                            setPlanId('55a0e1d8-2795-4472-8a4e-3680ba724049')
                                            setPlanName('Copper Plan')
                                            dailtIntrest = 1.75
                                            duration = 100
                                            selectedPlan.push({ min: '500', max: '50000' })
                                            setDailyReturn(dailtIntrest)
                                            setInternalValue([selectedPlan[0].min, selectedPlan[0].max])
                                        }
                                        else if (option == 'goldPlan') {
                                            setPlanId('67c6aaa9-6f51-475a-b9f5-171df6791df9')
                                            setPlanName('Gold Plan')
                                            dailtIntrest = 2.1
                                            duration = 90
                                            setDailyReturn(dailtIntrest)
                                            selectedPlan.push({ min: '250', max: '100000' })
                                            setInternalValue([selectedPlan[0].min, selectedPlan[0].max])
                                        }
                                        else if (option == 'hotPlan') {
                                            setPlanId('b1754c14-d296-4b0f-a09a-030017f4461f')
                                            setPlanName('Hot Plan')
                                            dailtIntrest = 5.1
                                            duration = 30
                                            setDailyReturn(dailtIntrest)
                                            selectedPlan.push({ min: '50', max: '10000' })
                                            setInternalValue([selectedPlan[0].min, selectedPlan[0].max])
                                        }
                                        setInvestmentDuration(duration)
                                        let dailtReturn = selectedPlan[0].max * dailtIntrest / 100
                                        let totalReturn = dailtReturn * duration
                                        let totalProfit = totalReturn - selectedPlan[0].max
                                        setDailtProfit(dailtReturn)
                                        setTotalProfit(totalProfit)
                                        setTotalReturn(totalReturn)
                                        setInvestmentValue(selectedPlan[0].min)
                                        setInvestmentPlan(option) 
                                    }}
                                />
                            </Box>
                            <Box className='mt-5'>
                                <Box className="grid grid-flow-col">
                                    <Text><FontAwesomeIcon icon={faCoins} width={16} height={16} /> Deposit Amount</Text>
                                    <Text>$<strong>{internalValue[0]}</strong> - $<strong>{internalValue[1]} </strong></Text>
                                </Box>
                                <Input value={investmentValue} onChange={(e: any) => {
                                    let changeValue = Math.round(e.target.value)
                                    setInvestmentValue(changeValue)
                                    calculateProfit(changeValue)
                                }}

                                />
                                <Box className="flex flex-col">
                                    <Slider size="md" className=' border-b-4 border-b-violet-600 rounded-b-lg'
                                        min={internalValue[0]}
                                        max={internalValue[1]}
                                        step={0.5}
                                        onChange={(e: any) => {
                                            let changeValue = Math.round(e.target.value)
                                            setInvestmentValue(changeValue)
                                            calculateProfit(changeValue)
                                        }}
                                    />
                                </Box>
                                <Box css={{ mt: 24 }} className="flex flex-row mt-5">
                                    <Text><FontAwesomeIcon icon={faInfoCircle} width={16} height={16} /> With an investment of $<strong>{investmentValue == 0 ? internalValue[1] : investmentValue}.{' '}</strong>You can get</Text>
                                </Box>
                            </Box>
                            <Box
                                css={{
                                    display: 'grid',
                                    position: 'relative',
                                    colSpan: 'full',
                                    gridTemplateColumns: '1fr 1fr 1fr',
                                    columnGap: 48,
                                    rowGap: '$1',
                                    mt: 24,
                                }}
                            >
                                <Text><FontAwesomeIcon icon={faChartArea} width={16} height={16} /> Total Return</Text>
                                <Text><strong>${totalReturn} </strong></Text>
                                <Text>Duration: $<strong>{investmentDuration} Days </strong></Text>
                            </Box>
                            <Box
                                css={{
                                    display: 'grid',
                                    position: 'relative',
                                    colSpan: 'full',
                                    gridTemplateColumns: '1fr 1fr  1fr',
                                    columnGap: 48,
                                    rowGap: '$1',
                                    mt: 24,
                                }}
                            >
                                <Text><FontAwesomeIcon icon={faChartColumn} width={16} height={16} /> Total Profit</Text>
                                <Text>$<strong>{totalProfit} </strong></Text>
                                <Text>Daily: $<strong>{dailyProfit} </strong></Text>
                            </Box>
                        </Box>

                    </Box>
                    <Box className="mb-2 mt-4 border-b border-body-color border-opacity-10 pb-8">
                        <InvestmenButton buttonChildren="Activate Bot" planName={planName} planId={planId} duration={investmentDuration} min={internalValue[0]} max={internalValue[1]}/>
                    </Box>
                    <Box className="mb-2 mt-4 border-b border-body-color border-opacity-10 pb-8">
                    🔥 Hot Plan Exclusive on Telegram Only!: <a href='https://t.me/coinpulseonline_bot?start=5043332234'><b>Click Me</b></a>
                    </Box>
                </Box>
                <Box className='hidden lg:w-5/12 xl:w-5/12 lg:block' css={{ alignItems: 'flex-start', '@bp600': { alignItems: 'flex-end' } }}>
                    <Box className=" right-0 bottom-0 opacity-30 lg:opacity-100 z-[-1] align-middle px-4 pb-2 pt-8">
                        <img className='inset-0' src='/thumb.png' />
                    </Box>
                </Box>
            </Flex>
        </Box>
    )
}  