import { useCollections } from '@reservoir0x/reservoir-kit-ui'
import { Text, Button, Box } from '../primitives'
import {
    DropdownMenuItem,
    DropdownMenuContent,
} from '@/components/primitives/Dropdown'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export type InvestmentCoinOption = "btnBTC" | "btnLTC" | "btnETH" | "btnUSDT" | "btnUSDC" | "btnMATIC" | "btnBCH" | "btnDOGE" | "btnBNB" | "btnTRX" | "btnSOL" | "btnADA"

const sortingOptions: InvestmentCoinOption[] = [
    'btnBTC',
    'btnLTC',
    'btnETH',
    'btnUSDT',
    'btnUSDC',
    'btnMATIC',
    'btnBCH',
    'btnDOGE',
    'btnBNB',
    'btnTRX',
    'btnSOL',
    'btnADA',
]

const coinForInvestmentOption = (
    option: InvestmentCoinOption,
    compact: boolean
) => {
    switch (option) {
        case 'btnBTC':
            return 'Bitcoin'
        case 'btnLTC':
            return 'Litecoin'
        case 'btnETH':
            return 'Ethereum'
        case 'btnUSDT':
            return 'USDT(SOL)'
        case 'btnUSDC':
            return 'USDC(SOL)'
        case 'btnMATIC':
            return 'MATIC'
        case 'btnBCH':
            return 'Bitcoin Cash'
        case 'btnDOGE':
            return 'DOGE'
        case 'btnBNB':
            return 'BNB(BEP20)'
        case 'btnTRX':
            return 'Tron'
        case 'btnSOL':
            return 'Solana'
        case 'btnADA':
            return 'ADA(BEP20)'
    }
}

type Props = {
    compact?: boolean
    option: InvestmentCoinOption,
    onOptionSelected: (option: InvestmentCoinOption) => void
}

const CoinDropDown: FC<Props> = ({
    compact = false,
    option,
    onOptionSelected,
}) => {
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button
                    color="gray3"
                    css={{
                        width: '100%',
                        px: '14px',
                        py: '12px',
                        justifyContent: 'center',
                        borderRadius: 8,
                        fontFamily: '$body',
                        fontSize: 16,
                        color: '$gray12',
                        backgroundColor: '$gray3',
                        $$focusColor: '$gray3',
                        '&::placeholder': { color: '$gray10' },
                        '&:focus': { boxShadow: '0 0 0 2px $$focusColor' },
                        '@md': {
                            px: '$5',
                        },
                    }}
                >
                    <Text style="body1">{coinForInvestmentOption(option, compact)}</Text>
                    <Box
                        css={{
                            transition: 'transform',
                            '[data-state=open] &': { transform: 'rotate(180deg)' },
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronDown} width={16} />
                    </Box>
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenuContent
             className='w-full'
                css={{
                    mt: '$2',
                    zIndex: 1000,
                    width: 'auto',
                    maxHeight: 300,
                    overflow: 'auto',
                    minWidth: 'max-content',
                    '@md': {
                        minWidth: 'max-content',
                    },
                }}
            >
                {sortingOptions.map((optionItem) => (
                    <DropdownMenuItem
                        key={optionItem}
                        css={{ py: '$3', px: '$1', display: 'flex', gap: '$2' }}
                        onClick={() =>
                            onOptionSelected(optionItem as InvestmentCoinOption)
                        }
                    >
                        {coinForInvestmentOption(optionItem, false)}
                    </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
        </DropdownMenu.Root>
    )
}

export default CoinDropDown
