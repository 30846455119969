import React, { ComponentProps, FC, ReactNode, useState, useEffect } from 'react'
import { SWRResponse } from 'swr'
import { useWalletClient, useAccount, } from 'wagmi' 
import { Button, Text, Input, Box } from '@/components/primitives'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { CSS } from '@stitches/react'
import { useMarketplaceChain } from '@/hooks'
import { CreateDepositAddress } from '@/utils/coinbase'
import CoinPaymentsButton from '@/components/home/CoinPaymentsButton'

type Props = {
  planId: any,
  planName: string,
  duration: number,
  min:number,
  max:number,
  buttonCss?: CSS
  buttonProps?: ComponentProps<typeof Button>
  buttonChildren?: ReactNode
  mutate?: SWRResponse['mutate']
}

const InvestmenButton: FC<Props> = ({
  planId,
  planName,
  duration,
  min,
  max,
  mutate,
  buttonCss,
  buttonProps = {},
  buttonChildren,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { address, isConnected } = useAccount()
  const [depositAmount, setDepositAMout] = useState<number>();
  const { data: signer } = useWalletClient()
  const { openConnectModal } = useConnectModal() 
  const marketplaceChain = useMarketplaceChain() 
   
  const canBuy = isConnected

  return !canBuy ? (
    <Box
      css={buttonCss}
      className='flex w-full items-center justify-center rounded-md bg-primary p-1 text-base font-semibold transition duration-300 ease-in-out hover:bg-opacity-80'
    >
      <w3m-button label='Activate Bot' />
    </Box>
  ) : (
    <>
      <Button onClick={() => setShowModal(true)} className="flex w-full items-center justify-center rounded-md bg-primary p-3 text-base font-semibold transition duration-300 ease-in-out hover:bg-opacity-80">Activate Bot
      </Button>
      {showModal ? (
        <>
          <Box
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <Box className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <Box css={{ backgroundColor: '$gray3' }} className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                {/*header*/}
                <Box className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    {planName}
                  </h3>
                  <Button
                    className="p-1 ml-auto border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none rounded-full"
                    onClick={() => setShowModal(false)}
                  >
                     ×
                  </Button>
                </Box>
                {/*body*/}
                <Box className="relative p-6 flex-auto">
                  <Text>How much mou want to invest?</Text>
                  <Box className='bg-primary text-primary flex-col justify-center items-center border-2 rounded-xl'>
                    <Input onChange={(e) => setDepositAMout(e.target.valueAsNumber)} type='number' />
                  </Box>
                  <Text className='text-xs mt-4 text-yellow-500' as='p' ><strong>Note:{' '}</strong> Maximize your investment potential by ensuring that your deposited amount includes the applicable wallet network fees. For example, if the network fee is 0.001 LTC, you should deposit a total of 1.001 LTC to ensure that your investment of 1 LTC reaches our platform after deducting the network fee.</Text>
                </Box>
                {/*footer*/}
                <Text className='justify-center items-center mx-6'>Deposit Through</Text>
                <Box className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <Button disabled={!depositAmount}
                    className=" active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 w-full text-center"
                    onClick={async () => {
                      const result = await CreateDepositAddress(planId, depositAmount, address?.toLowerCase(), duration)
                      window.location.assign(result?.data?.hosted_url)
                    }}
                  >
                    Coinbase
                  </Button>
                  <CoinPaymentsButton planId={planId} duration={duration} planName={planName} max={max} min={min}/>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="opacity-25 fixed inset-0 z-40 bg-black"></Box>
        </>
      ) : null}
    </>
  )
}

export default InvestmenButton
